import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';

import { QueryParamsInterface } from '@components/ng-dynamic-http-table';
import { TranslateService } from '@ngx-translate/core';
import { Deserialize, IJsonObject, Serialize } from 'dcerialize';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { SnackbarStatus } from 'src/definitions/snackbar-status.enum';
import { UserRole } from 'src/definitions/user-role.enum';
import { UserStatus } from 'src/definitions/user-status.enum';
import { CredentialsInterface } from 'src/definitions/user.interface';
import { Operator, OperatorList } from 'src/models/operator';
import { ReviewList } from 'src/models/review';
import { Client, User, UserData, UserDataPassword, UserList, UserSupport } from 'src/models/user';
import { AuthService } from 'src/shared-components/ng-login/auth/auth.service';
import { setStorageObject } from 'src/utils/storage-manager';

import { ApiService } from './api.service';
import { CustomSnackbarService } from './custom-snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  /**
   * API path
   */
  path = '/user';

  /**
   * The Observable with a valid email address to reset the password
   */
  private resetTokenValid: BehaviorSubject<string | null>;

  /**
   * The Subject signaling that the user data has been loaded
   */
  private userSubject: BehaviorSubject<UserData | undefined> = new BehaviorSubject<UserData | undefined>(
    AuthService.getUserData()
  );

  /**
   * The Observable signaling that the user data has been loaded
   */
  user$: Observable<UserData | undefined> = this.userSubject.asObservable();

  constructor(
    protected http: HttpClient,
    private snackbarService: CustomSnackbarService,
    private apiService: ApiService,
    private translateService: TranslateService,
    private router: Router,
    private destroyRef: DestroyRef
  ) {
    this.path = this.apiService.getApiUrl() + this.path;
    this.resetTokenValid = new BehaviorSubject<string | null>(null);
  }

  /**
   * Start the restore password process
   */
  statusList(): string[] {
    return Object.values(UserStatus).map(
      (status: UserStatus) => status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()
    );
  }

  emitUser(user: UserData | undefined): void {
    this.userSubject.next(user);
  }

  /**
   * Start the restore password process
   */
  resetPassword(email: string, successMsgKey: string, type: UserRole): Observable<void> {
    return this.http.post<void>(`${this.path}/restore-password`, { email: email, type: type }).pipe(
      takeUntilDestroyed(this.destroyRef),
      tap(() => {
        this.snackbarService.present(this.translateService?.instant(successMsgKey));
        type === UserRole.OPERATOR ? this.router.navigateByUrl('/operator') : this.router.navigateByUrl('/login');
      })
    );
  }

  /**
   * Validate the set password token
   * @returns user email
   */
  validateSetPasswordToken(token: string): Observable<string> {
    return this.http.get<string>(`${this.path}/password/${token}`).pipe(
      takeUntilDestroyed(this.destroyRef),
      catchError((err: HttpErrorResponse) => this.snackbarService.showError(err))
    );
  }

  /**
   * Validate the set password token
   * @returns user email
   */
  setPassword(type: UserRole, password = '', token = ''): Observable<void> {
    return this.http.patch<void>(`${this.path}/set-password/${token}`, { password: password, type: type }).pipe(
      takeUntilDestroyed(this.destroyRef),
      tap(() => {
        this.snackbarService.present(this.translateService?.instant('AuthForm.PasswordSet'), SnackbarStatus.Success);
        type === UserRole.OPERATOR ? this.router.navigateByUrl('/operator') : this.router.navigateByUrl('/login');
      }),
      catchError((err: HttpErrorResponse) => this.snackbarService.showError(err))
    );
  }

  /**
   * CRUD profile method
   * @returns element retrieved profile from the CRUD service
   */
  profile(): Observable<User> {
    return this.http.get<IJsonObject>(`${this.path}/profile`).pipe(
      takeUntilDestroyed(this.destroyRef),
      map((userData) => Deserialize(userData, () => User)),
      tap((user) => {
        this.userSubject.next(user);
      }),
      catchError((err: HttpErrorResponse) => this.snackbarService.showError(err))
    );
  }

  /**
   * Get client's details
   */
  clientDetails(clientId: number): Observable<Client> {
    return this.http.get<IJsonObject>(`${this.path}/client/${clientId}`).pipe(
      takeUntilDestroyed(this.destroyRef),
      map((client) => Deserialize(client, () => Client)),
      catchError((err: HttpErrorResponse) => this.snackbarService.showError(err))
    );
  }

  updateClient(client: User): Observable<Client> {
    return this.http
      .put<IJsonObject>(
        `${this.path}/client/${client.id}`,
        Serialize(client, () => User)
      )
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map((updatedClient) => Deserialize(updatedClient, () => Client)),
        catchError((error) => {
          throw error;
        })
      );
  }

  /**
   * CRUD operator profile method
   * @returns element retrieved profile from the CRUD service
   */
  operatorProfile(): Observable<Operator> {
    return this.http.get<IJsonObject>(`${this.path}/operator/profile`).pipe(
      takeUntilDestroyed(this.destroyRef),
      map((operatorData) => Deserialize(operatorData, () => Operator)),
      catchError((err: HttpErrorResponse) => this.snackbarService.showError(err))
    );
  }

  /**
   * Validate the signup confirmation token
   * @returns user email
   */
  validateSignUpConfirmationToken(role: UserRole, token: string): Observable<string> {
    const roleString = role.toString().toLowerCase();

    return this.http.patch<string>(`${this.path}/signup/${roleString}/${token}`, { activate: true });
  }

  /**
   * Starts a Client registration process
   * @param email - Email of the client
   * @param name - Name of the client
   * @param password - Password of the client
   * @param successMsgKey - Success snackbar message for the client
   */
  registerClient(email: string, name: string, password: string, successMsgKey: string): Observable<void> {
    return this.http.post<void>(`${this.path}/client/signup`, { email: email, password: password, name: name }).pipe(
      takeUntilDestroyed(this.destroyRef),
      tap(() => {
        this.snackbarService.present(this.translateService?.instant(successMsgKey), SnackbarStatus.Success);
      }),
      catchError((err: HttpErrorResponse) => this.snackbarService.showError(err))
    );
  }

  /**
   * Starts a Operator registration process
   *
   * @param operator - Operator that tries to sign up
   */
  registerOperator(operator: Operator): Observable<void> {
    return this.http
      .post<void>(
        `${this.path}/operator/signup`,
        Serialize(operator, () => Operator)
      )
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => {
          this.snackbarService.present(
            this.translateService?.instant('OperatorSignUp.Success'),
            SnackbarStatus.Success
          );
        }),
        catchError((err: HttpErrorResponse) => this.snackbarService.showError(err))
      );
  }

  /**
   * CRUD profile method
   * @returns element retrieved profile from the CRUD service
   */
  clientProfile(): Observable<User> {
    return this.http.get<IJsonObject>(`${this.path}/client/profile`).pipe(
      takeUntilDestroyed(this.destroyRef),
      map((userData) => Deserialize(userData, () => User)),
      catchError((err: HttpErrorResponse) => this.snackbarService.showError(err))
    );
  }

  /**
   * Retrieves the list of reviews for the given operator
   * @param operatorId
   */
  listReviewsByOperator(operatorId: number): Observable<ReviewList> {
    return this.http.get<IJsonObject>(`${this.path}/${operatorId}/review`).pipe(
      takeUntilDestroyed(this.destroyRef),
      map((reviews) => Deserialize(reviews, () => ReviewList)),
      catchError((err: HttpErrorResponse) => this.snackbarService.showError(err))
    );
  }

  /**
   * updates the client profile with the values given
   * @returns user changed
   */
  updateClientProfile(client: User): Observable<User> {
    return this.http
      .put<IJsonObject>(
        `${this.path}/client/profile`,
        Serialize(client, () => User)
      )
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => {
          const clientData = AuthService.getUserData();
          if (clientData) {
            clientData.name = client?.name;
            this.emitUser(clientData);
            setStorageObject(
              'userData',
              Serialize(clientData, () => UserData),
              'local'
            );
          }
        }),
        map((userData) => Deserialize(userData, () => User)),
        catchError((err: HttpErrorResponse) => this.snackbarService.showError(err))
      );
  }

  /**
   * Validate the set password token
   * @returns user email
   */
  changeClientPassword(type: UserRole, password = ''): Observable<void> {
    return this.http.patch<void>(`${this.path}/client/change-password`, { password: password, type: type }).pipe(
      takeUntilDestroyed(this.destroyRef),
      tap(() => {
        this.snackbarService.present(this.translateService?.instant('AuthForm.PasswordSet'), SnackbarStatus.Success);
      }),
      catchError((err: HttpErrorResponse) => this.snackbarService.showError(err))
    );
  }

  /**
   * Change operator password
   * @returns user email
   */
  changeOperatorPassword(type: UserRole, password = '', currentPassword = ''): Observable<void> {
    return this.http
      .patch<void>(
        `${this.path}/operator/change-password`,
        Serialize(
          {
            currentPassword: currentPassword,
            password: password,
            type: type
          },
          () => UserDataPassword
        )
      )
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => {
          this.snackbarService.present(this.translateService?.instant('AuthForm.PasswordSet'), SnackbarStatus.Success);
        }),
        catchError((err: HttpErrorResponse) => this.snackbarService.showError(err))
      );
  }

  /**
   * List all the operators in the platform
   * @returns List of operators
   */
  listOperatorsDetails(apsParams: QueryParamsInterface): Observable<OperatorList> {
    const params = new HttpParams({
      fromObject: { ...apsParams }
    });

    return this.http.get<IJsonObject>(`${this.path}/operators-details`, { params }).pipe(
      takeUntilDestroyed(this.destroyRef),
      map((operators) => Deserialize(operators, () => OperatorList)),
      catchError((err: HttpErrorResponse) => this.snackbarService.showError(err))
    );
  }

  /**
   * List all the clients in the platform
   * @returns List of operators
   */
  listClientsDetails(apsParams: QueryParamsInterface): Observable<UserList> {
    const params = new HttpParams({
      fromObject: { ...apsParams }
    });

    return this.http.get<IJsonObject>(`${this.path}/clients-list`, { params }).pipe(
      takeUntilDestroyed(this.destroyRef),
      map((clients) => Deserialize(clients, () => UserList)),
      catchError((err: HttpErrorResponse) => this.snackbarService.showError(err))
    );
  }

  /**
   * Get the details of an operator given its id
   * @param operatorId the id of the operator
   * @returns the operator details
   */
  getOperatorDetails(operatorId: number): Observable<Operator> {
    return this.http.get<IJsonObject>(`${this.path}/${operatorId}/operator`).pipe(
      takeUntilDestroyed(this.destroyRef),
      map((operator) => Deserialize(operator, () => Operator)),
      catchError((err: HttpErrorResponse) => this.snackbarService.showError(err))
    );
  }

  /**
   * Checks if the operator is verified
   * @param operatorId the id of the operator
   * @returns if the operator is verified in this dates
   */
  checkOperatorVerification(operatorId: number): Observable<{ [key: string]: boolean }> {
    return this.http.get<{ [key: string]: boolean }>(`${this.path}/${operatorId}/operator/verification`).pipe(
      takeUntilDestroyed(this.destroyRef),
      catchError((err: HttpErrorResponse) => this.snackbarService.showError(err))
    );
  }

  /**
   * Sends a support message to the platform
   * @param userSupport the data of the contact form
   */
  sendSupportMessage(userSupport: UserSupport): Observable<void> {
    return this.http.post<void>(
      `${this.path}/contact`,
      Serialize(userSupport, () => UserSupport)
    );
  }

  /**
   * Get the status of the user
   * @param credentials
   */
  getStatus(credentials: CredentialsInterface): Observable<UserStatus> {
    return this.http.post<IJsonObject>(`${this.path}/status`, credentials).pipe(
      takeUntilDestroyed(this.destroyRef),
      map((response) => {
        const statusString = response['status'];

        return UserStatus[statusString as keyof typeof UserStatus];
      })
    );
  }

  /**
   * Activates the account of a user
   * @param clientID - The ID of the client
   * @param role - The role of the user
   */
  activateUser(clientID: number, role: string): Observable<void> {
    return this.http.put<void>(`${this.path}/${clientID}/activate`, {}).pipe(
      takeUntilDestroyed(this.destroyRef),
      tap(() => {
        this.snackbarService.present(
          this.translateService?.instant(`AccountManagement.${role}EnableSuccess`),
          SnackbarStatus.Success
        );
      }),
      catchError((err: HttpErrorResponse) => this.snackbarService.showError(err))
    );
  }

  /**
   * Disables the account of a user
   * @param clientID - The ID of the client
   * @param role - The role of the user
   */
  disableUser(clientID: number, role: string): Observable<void> {
    return this.http.put<void>(`${this.path}/${clientID}/disable`, {}).pipe(
      takeUntilDestroyed(this.destroyRef),
      tap(() => {
        this.snackbarService.present(
          this.translateService?.instant(`AccountManagement.${role}DisableSuccess`),
          SnackbarStatus.Success
        );
      }),
      catchError((err: HttpErrorResponse) => this.snackbarService.showError(err))
    );
  }

  /**
   * Deletes the account of a user
   * @param clientID - The ID of the client
   * @param role - The role of the user
   */
  deleteUser(clientID: number, role: string): Observable<void> {
    return this.http.delete<void>(`${this.path}/${clientID}`).pipe(
      takeUntilDestroyed(this.destroyRef),
      tap(() => {
        this.snackbarService.present(
          this.translateService?.instant(`AccountManagement.${role}DeleteSuccess`),
          SnackbarStatus.Success
        );
      }),
      catchError((err: HttpErrorResponse) => this.snackbarService.showError(err))
    );
  }

  /**
   * Update logo for the current operator
   */
  updateLogo(profilePicture?: File): Observable<Operator> {
    // Create a new FormData instance to hold the file data
    const formData = new FormData();
    if (profilePicture) {
      formData.append('file', profilePicture);
    }

    return this.http.patch<IJsonObject>(`${this.path}/profile/logo`, formData).pipe(
      tap(() => {
        if (profilePicture) {
          this.snackbarService.present(
            this.translateService?.instant(
              'OperatorDetailsComponent.ManageProfilePicture.EditProfilePictureModal.SuccessUpload'
            ),
            SnackbarStatus.Success
          );
        } else {
          this.snackbarService.present(
            this.translateService?.instant(
              'OperatorDetailsComponent.ManageProfilePicture.EditProfilePictureModal.SuccessDelete'
            ),
            SnackbarStatus.Success
          );
        }
      }),
      map((newUser) => {
        const updatedOperator = Deserialize(newUser, () => Operator);

        const operatorData = AuthService.getUserData();
        if (operatorData) {
          operatorData.logo = updatedOperator.logo;
          this.userSubject.next(operatorData);
          setStorageObject(
            'userData',
            Serialize(operatorData, () => UserData),
            'local'
          );
        }

        return updatedOperator;
      }),
      catchError((err: HttpErrorResponse) => this.snackbarService.showError(err))
    );
  }

  /**
   * Get the logo of the operator if it's verified
   * @param micrositeId
   */
  getOperatorLogo(micrositeId: string): Observable<{ [key: string]: string }> {
    return this.http.get<{ [key: string]: string }>(`${this.path}/${micrositeId}/logo`).pipe(
      takeUntilDestroyed(this.destroyRef),
      catchError((err: HttpErrorResponse) => this.snackbarService.showError(err))
    );
  }

  /**
   * Update the microsite domain for verified operators
   */
  updateMicrositeDomain(domain: string): Observable<void> {
    return this.http.patch<void>(`${this.path}/profile/domain`, { domain: domain }).pipe(
      takeUntilDestroyed(this.destroyRef),
      catchError((err: HttpErrorResponse) => this.snackbarService.showError(err))
    );
  }

  /**
   * Get the micrositeId of an operator with the given domain
   */
  getMicrositeId(domain: string): Observable<{ [key: string]: string }> {
    return this.http.get<{ [key: string]: string }>(`${this.path}/microsite-id/${domain}`).pipe(
      takeUntilDestroyed(this.destroyRef),
      catchError((err: HttpErrorResponse) => this.snackbarService.showError(err))
    );
  }
}
